.ks-c-drag__item {
  border: 1px solid transparent;
}

.ks-c-drag__item:active {
  cursor: grabbing;
}

.lineBlock + .lineBlock {
  margin-right: 1.5rem;
}

.ks-secondary-nav.is-dragging {
  cursor: grabbing;

  * {
    cursor: grabbing !important;
  }
}

.ks-c__ResizeContent {
  transition: transform 250ms ease;
}
